<template>
  <div class="page-wrap">
    <img
      class="page-img top-img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/YunNanDX/instantDiscount/top.png"
    />
    <div class="amount_wrap">
      <span class="unit">￥</span>
      <span class="amount-num">1</span>
      <div class="tag_wrap">内含满 1.01<br />减1券*1</div>
    </div>
    <div class="form_wrap">
      <input
        class="input"
        v-model="phone_number"
        type="tel"
        placeholder="请输入手机号码"
        maxlength="11"
      />
      <div class="order-button" @click="onSubmitOrder">
        立即领取
      </div>
      <div class="protocol-wrap" @click="checked = !checked">
        <img
          class="protocol-check"
          :src="`https://cdn.qiumo.net.cn/allsmarketmaterial/YunNanDX/instantDiscount/${
            checked ? 'checked' : 'unchecked'
          }.png`"
        />
        <span>我已阅读并同意</span>
        <span class="protocol-text" @click.stop="openDialog">《服务协议》</span>
      </div>
    </div>
    <img
      class="page-img apply-img"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/YunNanDX/instantDiscount/apply.png"
    />
    <div class="rule_wrap">
      <img
        class="page-img rule_bg1"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/YunNanDX/instantDiscount/rule_bg1_1.png"
      />
      <img
        class="page-img rule_bg2"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/YunNanDX/instantDiscount/rule_bg2.png"
      />
      <img
        class="page-img rule_bg3"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/YunNanDX/instantDiscount/rule_bg3.png"
      />
      <div class="rule-text">
        {{ instructionsText }}
      </div>
    </div>
    <van-popup v-model="visibility" class="dialog-wrap">
      <div class="dialog-content">
        <img
          class="dialog-bg"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/YunNanDX/instantDiscount/dialog_bg.png"
        />
        <div class="dialog-text">{{ indexProtocolText }}</div>
        <img
          class="confirm-btn"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/YunNanDX/instantDiscount/confirm.png"
          @click="visibility = false"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { orderAPI } from "@/api/YunNanDX/index";
import { Toast, Dialog } from "vant";
import { instructionsText, indexProtocolText } from "./data.js";
export default {
  data() {
    return {
      phone_number: "",
      checked: false,
      visibility: false,
      instructionsText,
      indexProtocolText,
      once: true,
    };
  },
  mounted() {
    Toast.loading({
      message: "加载中...",
      forbidClick: true,
      duration: 0,
    });
    const pageEl = document.querySelector(".page-wrap");
    const topImgEl = document.querySelector(".top-img");
    const applyImgEl = document.querySelector(".apply-img");
    const ruleBg1El = document.querySelector(".rule_bg1");
    const ruleBg2El = document.querySelector(".rule_bg2");
    const ruleBg3El = document.querySelector(".rule_bg3");

    let timer = setInterval(() => {
      if (
        topImgEl.complete &&
        applyImgEl.complete &&
        ruleBg1El.complete &&
        ruleBg2El.complete &&
        ruleBg3El.complete
      ) {
        Toast.clear();
        clearInterval(timer);
        pageEl.style.opacity = 1;
      }
    }, 10);
  },
  methods: {
    openDialog() {
      this.visibility = true;
      if (this.once) {
        this.$nextTick(() => {
          const dialogEl = document.querySelector(".dialog-content");
          const dialogBgEl = document.querySelector(".dialog-bg");
          const confirmBtnEl = document.querySelector(".confirm-btn");
          let timer = setInterval(() => {
            if (dialogBgEl.complete && confirmBtnEl.complete) {
              clearInterval(timer);
              dialogEl.style.opacity = 1;
            }
          }, 10);
        });
      }
      this.once = false;
    },
    onSubmitOrder() {
      if (!this.phone_number) return Toast("请输入手机号码");
      let filterPhone = /^1[3456789]\d{9}$/;
      const pass = filterPhone.test(this.phone_number);
      if (!pass) return Toast("手机号码格式不正确");
      if (!this.checked) return Toast("请阅读并同意服务协议");
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      orderAPI({
        sku: "202409171139",
        phone: this.phone_number,
      })
        .then((res) => {
          Toast.clear();
          if (+res.status !== 200) {
            Toast({
              message: res.msg,
              duration: 3000,
            });
          } else {
            Toast({
              message: "领取成功",
              type: "success",
            });
          }
        })
        .catch(() => {
          Toast.clear();
          Toast({
            message: "服务器错误",
            duration: 3000,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
img {
  margin-top: -1px;
}
.page-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  .page-img {
    width: 100vw;
  }
  .top-img {
    height: 930px;
  }
  .apply-img {
    height: 526px;
  }
  .rule_bg1 {
    height: 405px;
  }
  .rule_bg2 {
    height: 497px;
  }
  .rule_bg3 {
    height: 533px;
  }
  .amount_wrap {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: #fff;
    position: absolute;
    top: 380px;
    font-weight: 600;
    .unit {
      font-size: 28px;
    }
    .amount-num {
      font-size: 58px;
      line-height: 1;
    }
    .tag_wrap {
      background: url(https://cdn.qiumo.net.cn/allsmarketmaterial/YunNanDX/instantDiscount/tag_bg.png)
        no-repeat;
      color: #ff605f;
      font-size: 15px;
      text-align: center;
      width: 120px;
      height: 53px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: -130px;
      top: -26px;
    }
  }
  .form_wrap {
    position: absolute;
    left: 50%;
    top: 640px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .input {
      width: 460px;
      height: 84px;
      border: none;
      text-align: center;
      border-radius: 50px;
      background: #fff;
      padding: 0 32px;
      border: 2px solid #ffcba6;
      font-size: 28px;
    }
    .order-button {
      width: 462px;
      height: 97px;
      margin: 12px 0;
      background: url(https://cdn.qiumo.net.cn/allsmarketmaterial/YunNanDX/instantDiscount/submit_bg.png) no-repeat center / 100% 100%;
      display: flex;
      justify-content: center;
      color: #ff5010;
      font-weight: bold;
      font-size: 36px;
      line-height: 84px;
    }
    .protocol-wrap {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      line-height: 24px;
      color: #fffdde;
      .protocol-check {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      .protocol-text {
        color: #ffd86b;
      }
    }
  }
  .rule_wrap {
    width: 100vw;
    // height: 1455px;
    position: relative;
    .rule-text {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      white-space: pre-wrap;
      color: #1762e1;
      font-size: 28px;
      padding: 70px 92px 0;
      line-height: 1.6;
      font-weight: 500;
    }
  }

  .dialog-wrap {
    background: none;
    overflow: initial;
    .dialog-content {
      opacity: 0;
      position: relative;
      .dialog-bg {
        width: 640px;
        height: 1100px;
      }
      .dialog-text {
        height: 860px;
        overflow: auto;
        white-space: pre-wrap;
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        width: 584px;
        font-size: 24px;
        line-height: 1.5;
        color: #1762e1;
        white-space: pre-wrap;
        font-weight: 500;
      }
      .confirm-btn {
        width: 489px;
        height: 101px;
        position: absolute;
        bottom: 36px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
