import request from "@/utils/request";
const url = process.env.VUE_APP_BASE_YNDX_API

// 下单
export const orderAPI = (data) => request({
  url: '/latefall-marketing/order/create',
  method: 'post',
  ipBaseURL: url,
  data,
});
